import React, { Fragment } from "react";
import HistoryEntry from "./history_entry";
import { TrashIcon } from "@heroicons/react/outline";

export default function HistoryCard({ history, setHistory }) {
  console.log(history);
  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-lg divide-y divide-gray-100">
      <div className="flex-initial p-4 flex justify-between">
        <h2>History</h2>
        <button
          className="w-10 h-10 p-1 rounded-md text-red-800 bg-red-200 hover:bg-red-500 hover:text-white"
          onClick={() => setHistory({ nextIndex: 0, entryList: [] })}
        >
          <TrashIcon></TrashIcon>
        </button>
      </div>
      <div className=" flex-grow overflow-y-auto">
        <ul className="divide-y divide-gray-100">
          {history.entryList.reverse().map((entry, index) => (
            <HistoryEntry
              key={index}
              type={entry.type}
              duration={entry.duration}
            ></HistoryEntry>
          ))}
        </ul>
      </div>
    </div>
  );
}
