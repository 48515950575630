import React, { useState } from "react";
import "rsuite/dist/styles/rsuite-default.css";
import "./app.scss";
import TimerCard from "./components/timer_card";
import HistoryCard from "./components/history_card";
import KofiButton from "./components/kofi_button";
import dayjs from "dayjs";

export default function App() {
  const [history, setHistory] = useState({
    entryList: [
      {
        type: "pomodoro",
        duration: 100,
        startedAt: dayjs(),
      },
      {
        type: "pomodoro",
        duration: 200,
        startedAt: dayjs(),
      },
      {
        type: "pomodoro",
        duration: 200,
        startedAt: dayjs(),
      },
      {
        type: "pomodoro",
        duration: 200,
        startedAt: dayjs(),
      },
      {
        type: "pomodoro",
        duration: 200,
        startedAt: dayjs(),
      },
      {
        type: "pomodoro",
        duration: 200,
        startedAt: dayjs(),
      },
    ],
  });

  return (
    <div className="h-full bg-gradient-to-br from-red-500 to-red-800 text-gray-800 grid grid-cols-4 grid-rows-3 gap-4 p-4">
      <div className="title-column">
        <div className="h-full bg-white p-8 sm:rounded-lg shadow-lg">
          <h1>Tomato Timer</h1>
          <KofiButton />
        </div>
      </div>
      <div className="row-start-1 col-start-2 col-span-3 row-span-3">
        <TimerCard history={history} setHistory={setHistory} />
      </div>
      <div className="row-start-2 col-start-1 row-span-2">
        <HistoryCard history={history} setHistory={setHistory} />
      </div>
    </div>
  );
}
