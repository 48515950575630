import React from "react";

export default function KofiButton() {
  return (
    <a
      href="https://ko-fi.com/H2H53HXVE"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button id="kofi-button">
        <img
          height="36"
          style={{ border: "0px", height: "36px" }}
          src="https://cdn.ko-fi.com/cdn/kofi3.png?v=2"
          border="0"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </button>
    </a>
  );
}
