import React from "react";
import _ from "lodash";
import { SecondsToTimerDisplay } from "../utils/time_utils";

export default function HistoryEntry({ type, duration }) {
  return (
    <div className="p-4">
      <h3>{_.startCase(type)}</h3>
      Duration: {SecondsToTimerDisplay(duration)}
    </div>
  );
}
