import React, { useState, useEffect } from "react";
// Icons at https://next.rsuitejs.com/tools/icons
import { Progress } from "rsuite";
import { StopIcon, PlayIcon, PauseIcon } from "@heroicons/react/solid";

import TimeDisplay from "./generic/time_display";
import { SecondsToTimerDisplay } from "../utils/time_utils";

import finishedSound from "../assets/piano_notification.mp3";
import dayjs from "dayjs";

const timerConstants = {
  pomodoro: {
    name: "pomodoro",
    modeTotalSeconds: 60 * 25,
  },
  shortBreak: {
    name: "short break",
    modeTotalSeconds: 60 * 5,
  },
  longBreak: {
    name: "long break",
    modeTotalSeconds: 60 * 10,
  },
};

export default function TimerCard({ history, setHistory }) {
  const finishedSoundAudio = new Audio(finishedSound);

  const [timerData, setTimerData] = useState({
    secondsElapsed: 0,
    secondsElapsedSinceLastPause: 0,
    secondsLeft: timerConstants.pomodoro.modeTotalSeconds,
    modeTotalSeconds: timerConstants.pomodoro.modeTotalSeconds,
    mode: timerConstants.pomodoro.name,
    // When the timer has first been started fresh.
    modeStartedAt: null,
    // When the timer has last been started, including after being paused.
    modeStartedAtSinceLastPause: null,
  });
  const [isStarted, setIsStarted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isHistorySaved, setIsHistorySaved] = useState(false);

  const style = {
    width: 120,
    display: "inline-block",
    marginRight: 10,
  };

  function onPomodoroMode() {
    saveHistoryOfCurrentMode();
    setTimerData({
      secondsElapsed: 0,
      secondsElapsedSinceLastPause: 0,
      modeStartedAt: null,
      modeStartedAtSinceLastPause: null,
      secondsLeft: timerConstants.pomodoro.modeTotalSeconds,
      modeTotalSeconds: timerConstants.pomodoro.modeTotalSeconds,
      mode: timerConstants.pomodoro.name,
    });
    setIsActive(false);
    setIsFinished(false);
  }

  function onShortBreakMode() {
    saveHistoryOfCurrentMode();
    setTimerData({
      secondsElapsed: 0,
      secondsElapsedSinceLastPause: 0,
      modeStartedAt: null,
      modeStartedAtSinceLastPause: null,
      secondsLeft: timerConstants.shortBreak.modeTotalSeconds,
      modeTotalSeconds: timerConstants.shortBreak.modeTotalSeconds,
      mode: timerConstants.shortBreak.name,
    });
    setIsActive(false);
    setIsFinished(false);
  }

  function onLongBreakMode() {
    saveHistoryOfCurrentMode();
    setTimerData({
      secondsElapsed: 0,
      secondsElapsedSinceLastPause: 0,
      modeStartedAt: null,
      modeStartedAtSinceLastPause: null,
      secondsLeft: timerConstants.longBreak.modeTotalSeconds,
      modeTotalSeconds: timerConstants.longBreak.modeTotalSeconds,
      mode: timerConstants.longBreak.name,
    });
    setIsActive(false);
    setIsFinished(false);
  }

  function saveHistoryOfCurrentMode() {
    if (!timerData.modeStartedAt || isHistorySaved) {
      return;
    }
    setHistory({
      entryList: history.entryList.concat({
        type: timerData.mode,
        duration: timerData.secondsElapsed,
        startedAt: timerData.modeStartedAt,
      }),
    });
    setIsHistorySaved(true);
  }

  function toggle() {
    if (!isActive) {
      setIsActive(true);

      if (!isStarted) {
        // Starting a fresh timer.
        setTimerData({
          ...timerData,
          modeStartedAt: dayjs(),
          modeStartedAtSinceLastPause: dayjs(),
        });
        setIsStarted(true);
        setIsHistorySaved(false);
      } else {
        // Resuming an ongoing timer that was paused.
        setTimerData({
          ...timerData,
          secondsElapsedSinceLastPause: timerData.secondsElapsed,
          modeStartedAtSinceLastPause: dayjs(),
        });
      }
    } else {
      setIsActive(false);
    }
  }

  function reset() {
    saveHistoryOfCurrentMode();
    setTimerData({
      ...timerData,
      secondsLeft: timerData.modeTotalSeconds,
      secondsElapsed: 0,
      secondsElapsedSinceLastPause: 0,
      modeStartedAt: null,
      modeStartedAtSinceLastPause: null,
    });
    setIsStarted(false);
    setIsActive(false);
    setIsFinished(false);
  }

  function onTimerFinished() {
    setIsFinished(true);
    setIsActive(false);
    finishedSoundAudio.play();
  }

  // FIX: Timer is slow when it is inactive: https://stackoverflow.com/questions/23506103/setinterval-slows-down-with-tab-window-inactive
  useEffect(() => {
    let interval = null;
    if (isActive) {
      if (timerData.secondsLeft !== 0) {
        // This during countdown
        interval = setInterval(() => {
          let secondsElapsed =
            timerData.secondsElapsedSinceLastPause +
            dayjs().diff(timerData.modeStartedAtSinceLastPause, "s");
          console.log(secondsElapsed);
          setTimerData({
            ...timerData,
            secondsElapsed: secondsElapsed,
            secondsLeft: timerData.modeTotalSeconds - secondsElapsed,
          });
        }, 1000);
      } else {
        onTimerFinished();
      }

      // Update the tab title with timer
      document.title =
        SecondsToTimerDisplay(timerData.secondsLeft) +
        " (" +
        timerData.mode +
        ")";
    } else if (!isActive && timerData.secondsLeft !== 0) {
      // This is the pause condition
      clearInterval(interval);
    }
    // Clear the interval so it does not compound
    return () => clearInterval(interval);
  }, [isActive, timerData]);

  let toggleIcon = isActive ? <PauseIcon /> : <PlayIcon />;
  let finishedText = isFinished ? "Finished!" : "";
  let status = isFinished ? "success" : null;
  let percent =
    ((timerData.modeTotalSeconds - timerData.secondsLeft) /
      timerData.modeTotalSeconds) *
    100;

  return (
    <div className="h-full bg-white p-8 sm:rounded-lg shadow-lg">
      <h2>Timer</h2>
      <div style={style}>
        <Progress.Circle
          percent={percent}
          status={status}
          strokeColor="#ffd900"
          strokeWidth={8}
          trailColor="#4d2d04"
          trailWidth={5}
        />
      </div>
      <TimeDisplay secondsLeft={timerData.secondsLeft} />
      <div>{finishedText}</div>
      <button
        className="w-10 h-10 p-1 rounded-md text-red-800 bg-red-200 hover:bg-red-500 hover:text-white"
        onClick={reset}
      >
        <StopIcon />
      </button>
      {!isFinished && (
        <button
          className="w-10 h-10 p-1 rounded-md text-red-800 bg-red-200 hover:bg-red-500 hover:text-white"
          onClick={toggle}
        >
          {toggleIcon}
        </button>
      )}

      <button onClick={onPomodoroMode}>Pomodoro</button>
      <button onClick={onShortBreakMode}>Short Break</button>
      <button onClick={onLongBreakMode}>Long Break</button>
    </div>
  );
}
